




























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { BrandAutocompletesService } from '@/api/braendz';
import { BusyList } from '@/models/Busy';
import { AutocompleteMode, BrandStateCategory, FilterableField, SearchableField } from '@/models/Query';

export enum SearchMode {
    Name = "Name",
    RegistrationNumber = "RegistrationNumber"
}

@Component({
    model: {
        prop: "model",
        event: "inputChanged"
    },
})
export default class BrandSearchInput extends Vue {
    // Fields
    public substring: string | null = null;
    public autocompleteList = new BusyList<string>();

    // Component Properties:
    @Prop({ required: true })
    public model!: string | null;

    @Prop({ required: true, default: SearchMode.Name })
    public searchMode!: string;

    @Prop({ required: false })
    public solo?: boolean;

    @Prop({ required: false })
    public outlined?: boolean;

    @Prop({ required: false })
    public busy?: boolean;

    // Getter & Setter
    public get input(): string | null {
        return this.model;
    }
    public set input(value: string | null) {
        this.fireInputChanged(value);
    }

    public get label(): string {
        if(this.mode === SearchMode.Name) {
            return this.$t('brandSearchInput.labelBrandName').toString();
        }
        else if(this.mode === SearchMode.RegistrationNumber) {
            return this.$t('brandSearchInput.labelRegistrationNumber').toString();
        }
        return '';
    }

    public get menuProps() {
        if(!this.substring) {
            return { closeOnClick: true, value: false };
        }
        else {
            return { closeOnClick: true };
        }
    }

    public get mode(): string {
        return this.searchMode;
    }
    public set mode(value: string) {
        this.$emit('searchModeChanged', value);
    }

    // Component lifecycle methods:

    // Internal Event Subscriptions
    public substringChanged(substring: string | undefined | null): void {
        this.refreshAutocompleteList(substring);
    }

    // Methods:
    public async refreshAutocompleteList(substring: string | undefined | null): Promise<void> {
        if (!substring || substring.length < 3) {
            this.autocompleteList.list = [];
            return;
        }
        if (this.autocompleteList.isBusy) {
            return;
        }

        await this.autocompleteList.load(async () => {
            if (substring) {
                const result = await BrandAutocompletesService.autocompleteBrandNames(substring, {  
                    fields: [ SearchableField.Name ],
                    filters: [
                        { field: FilterableField.BrandStateCategory, value: BrandStateCategory.New },
                        { field: FilterableField.BrandStateCategory, value: BrandStateCategory.Alive }
                    ],
                    size: 15,
                    mode: AutocompleteMode.OneTermWithContext,
                    useFuzzyMatching: true
                });
                return result;
            }
            else
                return [];
        });
    }

    public filter(item: any, queryText: string, itemText: string): boolean {
        return true;
    }

    // Event Emitter:
    public fireInputChanged(value: string | null): void {
        this.$emit('inputChanged', value);
    }

}
